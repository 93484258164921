<!--  -->
<template>
  <div class="cell">
    <van-field
      v-model="value"
      @click="handlClick"
      v-bind="{
        ...fieldProps,
        readonly: true,
      }"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        @confirm="onConfirm"
        @cancel="showPicker = false"
        v-bind="pickerProps"
        :columns="columns"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPicker: false,
    };
  },
  props: {
    options: Array,
    value: [String, Number, Array],
    fieldProps: Object,
    pickerProps: Object,
  },
  computed: {
    columns() {
      return this.options.map((el) => el.label);
    },
  },
  components: {},

  mounted() {},

  methods: {
    handlClick() {
      if (
        this.fieldProps &&
        (this.fieldProps.readonly || this.fieldProps.disabled)
      )
        return;
      this.showPicker = true;
    },
    onConfirm(label) {
      const value = this.options.find((el) => el.label === label).value;
      this.$emit("input", value);
      this.showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>
