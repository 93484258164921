import request from "@/utils/request";
/**
 * @description查询所有的省份
 */

export function selectAllProvince(data) {
  return request({
    method: "GET",
    url: "/policy/facade/district/selectAllProvince",
    data,
  });
}

/**
 * @description 查询省级下边所有的市
 * @param {String} proCode
 */
export function selectByProvinceCode(proCode) {
  return request({
    method: "GET",
    url: "/policy/facade/district/selectByProvinceCode",
    params: { proCode: proCode },
  });
}

/**
 * @description 查询市下边的所有区县
 * @param {String} cityCode
 */
export function selectByCityCode(cityCode) {
  return request({
    method: "GET",
    url: "/policy/facade/district/selectByCityCode",
    params: { cityCode: cityCode },
  });
}
