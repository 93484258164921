import VanFieldSelect from "@/components/VanFieldSelect";
import VanFieldMonth from "@/components/VanFieldMonth";
import VanFieldDay from "@/components/VanFieldDay";
import VanFieldCalendar from "@/components/VanFieldCalendar";
import VanFieldArea from "@/components/VanFieldArea";



export default {
  data() {
    return {
      idCardTypeOptions: [
        {
          label: "居民身份证",
          value: "居民身份证",
        },
        {
          label: "港澳居民来往内地通行证",
          value: "港澳居民来往内地通行证",
        },
        {
          label: "台湾居民来往内地通行证",
          value: "台湾居民来往内地通行证",
        },
        {
          label: "护照",
          value: "护照",
        },
      ],
      educationOptions: [
        {
          label: "大专",
          value: "大专",
        },
        {
          label: "本科",
          value: "本科",
        },
        {
          label: "研究生",
          value: "研究生",
        },
      ],
      degreeOptions: [
        {
          label: "学士",
          value: "学士",
        },
        {
          label: "硕士",
          value: "硕士",
        },
        {
          label: "博士",
          value: "博士",
        },
      ],
    };
  },
  props: {
    defaultForm: Object,
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },
  watch: {
    userInfo: {
      deep: true,
      handler() {
        this.backFillUserInfo();
      },
    },
    defaultForm: {
      deep: true,
      handler() {
        this.form = {
          ...this.form,
          ...this.defaultForm,
          //家庭关系
          family: this.defaultForm.family
            ? this.defaultForm.family.length
              ? this.defaultForm.family
              : [
                {
                  familyTies: "",
                  familyName: "",
                  famIdCardType: "居民身份证",
                  famIdCardNum: "",
                  famDomicile: [],
                },
              ]
            : undefined,
        };
      },
    },
  },
  components: {
    VanFieldSelect,
    VanFieldCalendar,
    VanFieldArea,
    VanFieldMonth,
    VanFieldDay
  },
  created() {
    if (this.userInfo) {
      this.backFillUserInfo();
    }
    if (this.defaultForm) {
      this.form = {
        ...this.form,
        ...this.defaultForm,
        family: this.defaultForm.family
          ? this.defaultForm.family.length
            ? this.defaultForm.family
            : [
              {
                familyTies: "",
                familyName: "",
                famIdCardType: "居民身份证",
                famIdCardNum: "",
                famDomicile: [],
              },
            ]
          : undefined,
      };
    }
  },
  methods: {
    //获取用户信息 回显用户信息
    // backFillUserInfo() {
    //   getUserInfo().then(res => {
    //     this.form.applyName = res.data.rnName;
    //     this.form.phone = res.data.usrPhe;
    //     this.form.idCardNum = res.data.idCard;
    //   })
    // },
    backFillUserInfo() {
      this.form.applyName = this.userInfo.rnName;
      this.form.phone = this.userInfo.usrPhe;
      this.form.idCardNum = this.userInfo.idCard;
    },
  },
};
