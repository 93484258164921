<!--  -->
<template>
  <div class="cell">
    <van-field
      v-model="displayValue"
      @click="show = true"
      v-bind="{
        ...fieldProps,
        readonly: true,
      }"
    />
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-cascader
        active-color="#3C37D2"
        v-model="cascaderValue"
        title="请选择"
        :options="options"
        @close="show = false"
        @finish="onFinish"
        @change="handleChange"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  selectAllProvince,
  selectByProvinceCode,
  selectByCityCode,
} from "@/api/common";

export default {
  data() {
    return {
      show: false,
      // displayValue: "",
      cascaderValue: "",
      options: [],
      selectedOptions: [], //当前选择数组
    };
  },
  props: {
    value: Array, //[1,2,3] 省市区
    defaultField: String, //初始显示的值
    fieldProps: Object,
  },
  computed: {
    displayValue() {
      return this.selectedOptions.map((option) => option.text).join("/");
    },
  },
  watch: {
    value() {
      if (this.value && this.value.length === 3) {
        this.cascaderValue = this.value[2];
        this.initByValue();
      }
    },
  },
  created() {},
  mounted() {
    if (this.defaultField) {
      this.displayValue = this.defaultField;
    }
    if (this.value && this.value.length === 3) {
      this.initByValue();
    } else {
      this.lazyLoad(-1); //获取省
    }
  },
  methods: {
    initByValue() {
      //设置地区初始值
      this.cascaderValue = this.value[2];
      const selects = [];
      let cur1, cur2, cur3;
      this.lazyLoad(-1).then(() => {
        cur1 = this.options.find((el) => el.value === this.value[0]);
        selects.push(cur1);
        this.lazyLoad(0, this.value[0], selects).then(() => {
          cur2 = cur1.children.find((el) => el.value === this.value[1]);
          selects.push(cur2);
          this.lazyLoad(1, this.value[1], selects).then(() => {
            cur3 = cur2.children.find((el) => el.value === this.value[2]);
            this.selectedOptions = [cur1, cur2, cur3];
          });
        });
      });
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.selectedOptions = selectedOptions;
      this.$emit(
        "input",
        selectedOptions.map((el) => el.value)
      );
    },
    //获取市、区
    handleChange({ selectedOptions, tabIndex, value }) {
      this.lazyLoad(tabIndex, value, selectedOptions);
    },
    async lazyLoad(tabIndex, value, selectedOptions) {
      if (tabIndex === -1) {
        await this.axiosSelectAllProvince().then((res) => {
          const nodes = res.map((item) => ({
            value: item.proCode,
            text: item.proName,
            children: [],
          }));
          this.options = nodes;
        });
      }
      if (
        tabIndex === 0 &&
        selectedOptions &&
        !selectedOptions[0].children.length
      ) {
        await this.axiosSelectByProvinceCode(value).then((res) => {
          const nodes = res.map((item) => ({
            value: item.cityCode,
            text: item.cityName,
            children: [],
          }));
          selectedOptions[0].children = nodes;
        });
      }
      if (
        tabIndex === 1 &&
        selectedOptions &&
        !selectedOptions[1].children.length
      ) {
        await this.axiosSelectByCityCode(value).then((res) => {
          const nodes = res.map((item) => ({
            value: item.countryCode,
            text: item.countryName,
          }));

          selectedOptions[1].children = nodes;
        });
      }
    },
    axiosSelectAllProvince() {
      return selectAllProvince().then((res) => {
        if (res.code === 200) {
          return res.data;
        }
      });
    },
    axiosSelectByProvinceCode(proCode) {
      return selectByProvinceCode(proCode).then((res) => {
        if (res.code === 200) {
          return res.data;
        }
      });
    },
    axiosSelectByCityCode(cityCode) {
      return selectByCityCode(cityCode).then((res) => {
        if (res.code === 200) {
          return res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>
