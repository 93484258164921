<!--  -->
<template>
  <div class="cell">
    <van-field
      v-model="displaValue"
      @click="showPicker = true"
      v-bind="{
        ...fieldProps,
        readonly: true,
      }"
    />
    <!-- <van-calendar
      color="#3C37D2"
      :min-date="minDate"
      :max-date="maxDate"
      v-bind="calendarProps"
      :lazy-render="true"
      v-model="showPicker"
      @confirm="onChange"
    /> -->
    <van-popup v-model="showPicker" position="bottom">
      <inlineCalendar
        @change="onChange"
        v-bind="calendarProps"
        :defaultDate="defaultDate"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      showPicker: false,
      format: "YYYY-MM-DD",
      defaultDate: "",
    };
  },
  props: {
    value: [String, Array],
    fieldProps: Object,
    calendarProps: Object,
  },
  computed: {
    displaValue() {
      if (!this.calendarProps) return this.value;
      const { mode } = this.calendarProps;
      if (!this.value) return "";
      if (!mode || mode === "single") {
        return this.value;
      } else if (!mode || mode === "during") {
        return `${this.value[0]}~${this.value[1]}`;
      } else {
        if (Array.isArray(this.value)) {
          return this.value.join(",");
        } else {
          return this.value;
        }
      }
    },
    columns() {
      return this.options.map((el) => el.label);
    },
  },
  components: {},
  created() {
    if (this.value) {
      this.defaultDate = this.value;
    }
  },
  mounted() {},

  methods: {
    onChange(value) {
      if (!this.calendarProps) {
        this.$emit("input", this.formatDate(value));
        this.showPicker = false;
        return;
      }
      const { mode } = this.calendarProps;
      if (!mode || mode === "single") {
        this.$emit("input", this.formatDate(value));
        this.showPicker = false;
      } else if (!mode || mode === "during") {
        if (this.formatDate(value).length === 2) {
          this.$emit("input", this.formatDate(value));
          this.showPicker = false;
        }
      } else {
        this.$emit("input", this.formatDate(value));
      }
    },
    formatDate(value) {
      if (Array.isArray(value)) {
        return value.map((el) => dayjs(el).format(this.format));
      } else {
        return dayjs(value).format(this.format);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>