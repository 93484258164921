<!--  -->
<template>
  <div class="cell">
    <van-field
      v-model="value"
      @click="handlClick"
      v-bind="{
        ...fieldProps,
        readonly: true,
      }"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        show-toolbar
        @confirm="onConfirm"
        @cancel="showPicker = false"
        v-bind="{
          ...pickerProps,
        }"
        :min-date="minDate"
        :max-date="maxDate"
        v-model="currentDate"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPicker: false,
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
    };
  },
  props: {
    options: Array,
    value: [String, Number, Array],
    fieldProps: Object,
    pickerProps: Object,
  },
  computed: {},
  components: {},

  mounted() {},

  methods: {
    handlClick() {
      if (
        this.fieldProps &&
        (this.fieldProps.readonly || this.fieldProps.disabled)
      )
        return;
      this.showPicker = true;
    },
    onConfirm(value) {
      console.log(value);
      let time = new Date(value);
      let year = String(time.getFullYear()).padStart(2, "0");
      let month = String(time.getMonth() + 1).padStart(2, "0");
      let day = String(time.getDate()).padStart(2, "0");
      this.$emit("input", `${year}-${month}-${day}`);
      this.showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>
